@import "../../utils/styles/variables";

label {
  display: block;
  margin-bottom: 4px;
  color: $new-purple;
}

textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  border: 1px solid $grey;
  border-radius: 4px;
  color: $reviewer-dark;
  outline-width: 1px;
  outline-color: $new-purple;
  resize: none;

  &.resizable {
    resize: both;
  }

  &.error {
    border-color: $new-error-red;
  }

  &::placeholder {
    color: $grey;
    font-size: 0.9rem;
  }

  &:focus {
    border: 1px solid $new-purple;
  }

  &::-webkit-scrollbar {
    width: 12px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    border-right: 7px transparent solid;
    background-clip: padding-box;
  }
}

.errorLabel {
  display: block;
  margin: 10px 0 0;
  color: $new-error-red;
}
