@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      h1 {
        text-transform: uppercase;
        font-size: 1rem;
      }

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  div[data-testid="loading-form"] {
    width: 100%;
    padding: 18px 30px;
  }

  fieldset {
    width: 100%;
    padding-block: 30px 40px;
    padding-inline: 40px;
    border: none;

    h3 {
      margin-block: 2.2rem 0.5rem;
    }

    .inputWrapper {
      margin-bottom: 1.125rem;
    }

    input[type="text"],
    textarea {
      height: auto;
      width: 100%;

      &::placeholder {
        font-size: 14px;
      }
    }

    label[id="tag-error"] {
      color: $new-error-red;
    }
  }
}
