@import "../../utils/styles/variables";

.container {
  background-color: $new-student-accent;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .mainContent {
    display: flex;
    flex-flow: row nowrap;
    width: 70vw;
    justify-content: space-between;

    @media (max-width: 480px) {
      width: 90vw;
    }

    .firstContent {
      flex: 3;
      margin: 0 2rem 0 0;
      text-align: center;

      .title {
        width: 70%;
        color: $new-student-accent;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 32px auto;
      }

      @media (max-width: 980px) {
        display: none;
      }
    }

    .secondContent {
      flex: 2;
    }

    .card {
      background-color: white;
      border-radius: 10px;
      height: 553px;
      max-height: 80vh;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;

      .illustration {
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;

        svg {
          width: 100%;
          max-width: 680px;
          position: relative;
          margin: 0 1.5rem 0;
          bottom: 20px;
          height: 400px;
        }
      }

      .cardHeader {
        background-color: $reopen-test-yellow;
        color: $new-student-accent;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 0.9rem;
        line-height: 49px;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
        height: 49px;
      }

      .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo {
          width: 70%;
          margin: 1.4rem auto;
        }

        .form {
          max-width: 230px;

          input {
            min-width: 230px;
          }

          label {
            margin-top: 20px;
          }

          button {
            margin-top: 20px;
          }

          .errorText {
            color: $new-error-red;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            margin: 10px 0 11px;
            display: block;
            text-align: center;
          }

          .buttonsRow {
            display: flex;
            margin-top: 20px;
            width: 100%;
            justify-content: space-between;

            button + button {
              margin-left: 10px;
            }
          }
        }

        .loginButtonWrapper {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        &.passwordRedefinition {
          padding-bottom: 160px;
        }
      }
    }

    .linkButton {
      background: none;
      border: none;
      padding: 0;
      font-size: 1rem;
      margin: 20px 0;
      color: $new-student-accent;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
