@import "../../utils/styles/variables";
@import "../../utils/styles/mixins";

form.container {
  width: 100%;
  min-height: calculateRem(800px);
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
        color: $reviewer-dark;
      }
    }

    button {
      height: min-content;
    }
  }

  .fieldset {
    width: 360px;
    padding: 30px 40px 40px;
    border: none;

    input[type="text"] {
      width: 300px;
    }

    input + label {
      color: $new-purple;
    }

    input[type="checkbox"],
    label:nth-child(2) {
      margin-top: 30px;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: 30px;
    }

    label:first-child {
      margin-top: 0;
    }
  }
}
