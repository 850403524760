@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend,
strong {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      h1 {
        text-transform: uppercase;
        font-size: 1rem;
      }

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .rightButtons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  section {
    display: flex;
    gap: 100px;
    padding-block: 20px 40px;
    padding-inline: 40px;

    fieldset {
      border: none;

      .promptHeader {
        display: flex;
        flex-direction: row;
        margin-top: 1.25rem;

        & > label {
          margin-top: 0rem;
        }
      }

      .promptVisualizeButton {
        cursor: pointer;
        margin-left: -10px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      input[type="text"],
      input[type="number"] {
        width: 300px;

        &::placeholder {
          font-size: 14px;
        }
      }

      textarea {
        width: 440px;
        height: 330px;

        &::placeholder {
          font-size: 14px;
        }
      }

      label.error {
        color: $new-error-red;
      }

      input ~ label,
      div ~ label,
      div > label {
        margin-top: 24px;
      }

      label:first-child {
        margin-top: 0;
      }

      .promptSelectWrapper {
        > div {
          display: flex;
          align-items: center;
          gap: 20px;
          min-width: 300px;
        }
      }
    }

    aside {
      width: 100%;
      max-width: 800px;

      h2 {
        margin-bottom: 20px;
      }

      .loadingMessageWrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;

        img {
          width: 30px;
          height: 30px;
        }
      }

      textarea {
        &[id="answer_content"] {
          height: 400px;
        }
      }

      p {
        font-size: 1rem;
        display: block;
      }

      .ratingWrapper {
        margin-top: 1.4rem;
      }

      textarea[id="content"] {
        width: 400px;
        height: 300px;
      }

      button {
        margin-top: 20px;
      }
    }
  }
}
