.wrapper {
  table {
    width: 100%;
    border-radius: 10px;

    tr {
      width: 100%;
      height: 48px;
      margin-bottom: 20px;
      display: block;
    }

    tr:last-child {
      margin-bottom: 0;
    }
  }
}
