@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    button {
      height: min-content;
    }
  }

  .fieldset {
    padding: 30px 40px 40px;
    border: none;

    .selectWrapper {
      width: 300px;
    }

    input[type="text"],
    input[type="number"],
    textarea {
      width: 300px;
    }

    .sentenceValidationWrapper {
      display: flex;
      align-items: center;
      margin-top: 40px;

      button {
        margin: 20px 0 0 20px;
        height: fit-content;
      }
    }

    .resultsWrapper {
      max-width: 440px;
      margin-top: 20px;

      .result {
        font-weight: bold;

        svg {
          margin-right: 10px;
        }
      }
    }

    .loadingRowWrapper {
      margin-top: 14px;
    }

    legend {
      font-weight: bold;
      font-size: 18px;
      margin-top: 30px;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: 36px;
    }

    label:first-child {
      margin-top: 0;
    }

    input[type="checkbox"],
    input[type="checkbox"] + label {
      margin-top: 30px;
    }

    .errorLabel {
      color: $new-error-red;
      font-size: 16px;
      display: block;
      padding-left: 4px;
    }
  }
}
