@import "../../utils/styles/mixins";

form.manualScheduling {
  width: 100%;
  background-color: $white;
  border-radius: 0.5rem;

  fieldset {
    display: flex;
    flex-direction: row-reverse;
    align-items: space-between;
    justify-content: space-between;
    padding: 1.875rem 2.5rem 2.5rem;
    border: none;

    input ~ label,
    div > label {
      margin-top: 1.5rem;
    }

    .actionsWrapper {
      max-width: 30.5rem;
      width: 100%;

      h3 {
        margin-bottom: 0.5rem;
      }
    }

    .selectWrapper {
      max-width: 32.5rem;
      width: 100%;
    }
  }

  div[data-testid="loading-form"] {
    width: 100%;
    padding: 18px 30px;
  }

  .error {
    color: #f24e54;
  }
}

.container {
  @include listPageStyles;
}

.successText {
  color: $success;
}

.errorText {
  color: $new-error;
}

.waitingText {
  color: $warning;
}
