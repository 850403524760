.loadingForm {
  width: 300px;

  label {
    width: 130px;
    color: transparent;
  }

  input {
    margin-bottom: 40px;
    border: 0;
  }
}
