@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  .rightButtonsContainer {
    display: flex;

    button:first-child {
      margin-right: 10px;
    }
  }

  fieldset {
    padding: 30px 40px 40px;
    border: none;

    .blockVersionSelectWrapper {
      position: absolute;
      right: 60px;

      label {
        margin-top: 0;
      }
    }

    input[type="text"],
    textarea {
      width: 300px;

      &[disabled=""] {
        background-color: hsl(0, 0%, 95%);
        cursor: not-allowed;
      }
    }

    input ~ label,
    div > label {
      margin-top: 24px;
    }
  }

  .loadingWrapper {
    padding: 40px;
  }
}
