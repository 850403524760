@import "../../utils/styles/variables";
@import "../../utils/styles/mixins";

p,
h1,
h2,
h3,
h4,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: calculateRem(800px);
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calculateRem(18px) calculateRem(30px);
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: calculateRem(10px);
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: calculateRem(16px);
      }
    }

    button {
      height: min-content;
    }
  }

  .fieldset {
    padding: calculateRem(30px) calculateRem(40px) calculateRem(40px);
    border: none;

    input[type="text"],
    textarea {
      width: calculateRem(300px);
    }

    .loadingRowWrapper {
      margin-top: calculateRem(14px);
    }

    legend {
      font-weight: bold;
      font-size: calculateRem(18px);
      margin: calculateRem(30px) 0 0 0;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: calculateRem(36px);
    }

    label:first-child {
      margin-top: 0;
    }

    input[type="checkbox"],
    input[type="checkbox"] + label {
      margin-top: calculateRem(30px);
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calculateRem(600px);

    img {
      width: calculateRem(200px);
      height: calculateRem(200px);
    }

    h2 {
      margin-top: calculateRem(30px);
    }

    .resetDataButtonWrapper {
      margin-top: calculateRem(20px);
    }
  }
}
