@import "utils/styles/variables";
@import "utils/styles/mixins";

.modalWrapper {
  max-width: 37.5rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 3.438rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2,
  p {
    color: $reviewer-dark;
  }

  h2 {
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  p {
    margin: 1.125rem 0;
    font-size: 1.125rem;
    line-height: 1.185rem;
    text-align: center;
    max-width: 16.25rem;
  }

  ul {
    margin-block: 1rem;
    width: 100%;

    ul {
      margin-block: 0;
      padding-left: 1.25rem;
      padding-top: 0.25rem;

      li:last-child {
        margin-bottom: 0.875rem;
      }
    }
  }
}
