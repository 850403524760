@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend,
strong {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 50rem;
  background-color: $white;
  border-radius: 0.5rem;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.125rem 1.185rem;
    border-bottom: 0.063rem solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      h1 {
        text-transform: uppercase;
        font-size: 1rem;
      }

      button {
        margin-right: 0.625rem;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .rightButtons {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }
  }

  section {
    display: flex;
    gap: 6.25rem;
    padding-block: 1.25rem 2.5rem;
    padding-inline: 2.5rem;

    fieldset {
      border: none;

      .promptHeader {
        display: flex;
        flex-direction: row;
        margin-top: 1.25rem;

        & > label {
          margin-top: 0rem;
        }
      }

      input[type="text"],
      input[type="number"] {
        width: 31.25rem;

        &::placeholder {
          font-size: 0.875rem;
        }
      }

      label.error {
        color: $new-error-red;
      }

      input ~ label,
      div ~ label,
      div > label {
        margin-top: 1.5rem;
      }

      label:first-child {
        margin-top: 0;
      }
    }

    aside {
      width: 100%;
      border-radius: 1rem;
      margin-top: 1rem;
      height: 40rem;
      overflow-y: hidden;

      & > div {
        overflow-y: auto;
        height: 100%;
      }
    }
  }
}
