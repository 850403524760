@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  .rightButtonsContainer {
    display: flex;
    gap: 10px;
  }

  fieldset {
    padding: 0 40px 40px;
    border: none;

    .promptSelectWrapper {
      > div {
        display: flex;
        align-items: center;
        gap: 20px;
        min-width: 300px;
      }
    }

    .promptHeader {
      display: flex;
      flex-direction: row;
      margin-top: 1.25rem;

      & > label {
        margin-top: 0rem;
      }
    }

    .promptVisualizeButton {
      cursor: pointer;
      margin-left: -10px;
    }

    input[type="text"],
    textarea {
      width: 300px;

      &[disabled=""] {
        background-color: hsl(0, 0%, 95%);
        cursor: not-allowed;
      }
    }

    textarea {
      width: 440px;

      &[id="composition_string"] {
        height: 300px;
      }

      &[id="content"] {
        height: 300px;
      }
    }

    input ~ label,
    textarea ~ label,
    div > label,
    div ~ label {
      margin-top: 24px;
    }
  }

  .loadingWrapper {
    padding: 40px;
  }
}
