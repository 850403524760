@import "../../utils/styles/variables";

.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  h1,
  h2,
  td,
  th {
    color: $reviewer-dark;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $reopen-test-grey;

    h1 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 0;
    }

    button {
      height: min-content;
    }
  }

  .tableWrapper {
    padding: 30px 30px 40px;

    .subHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .searchContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        input[data-testid="search"] {
          padding-right: 0;
        }

        .numberOfEntitiesPerPageSelectTooltip {
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          font-size: 14px;
          padding: 20px 13px;
          box-shadow: 1px 1px 2px $scale13;
          line-height: 21px;

          .wrapper {
            width: 200px;

            p {
              color: $white;
            }
          }
        }
      }

      .selectWrapper {
        display: flex;

        .numberOfEntitiesPerPageSelectWrapper {
          margin-left: 10px;
        }

        .numberOfEntitiesPerPageSelectTooltip {
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          font-size: 14px;
          padding: 20px 13px;
          box-shadow: 1px 1px 2px $scale13;
          line-height: 21px;

          .wrapper {
            width: 200px;

            p {
              color: $white;
            }
          }
        }
      }
    }

    table {
      width: 100%;
      margin-top: 30px;
      padding-left: 18px;

      .rulesPrecision {
        svg {
          margin-right: 6px;
        }

        .rulesPrecisionTooltipWrapper {
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          font-size: 14px;
          padding: 20px 13px;
          box-shadow: 1px 1px 2px $scale13;
          line-height: 21px;

          ul {
            list-style: none;
            padding: 0;

            li {
              margin-bottom: 10px;
              color: $white;
            }
          }
        }
      }

      th,
      td {
        text-align: left;
        padding: 14px 0;
        border-bottom: 1px solid $new-light-gray;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      td {
        .buttonWrapper {
          display: flex;
          button,
          svg {
            background-color: transparent;
            cursor: pointer;
            border: 0;
            margin: 0;
            transition: filter 0.3s linear;

            &:hover {
              filter: brightness(80%);
            }
          }

          button {
            margin-left: 14px;
          }

          .info {
            button {
              margin-left: 14px;
              cursor: pointer;
            }

            .newRuleVersionWarningTooltipWrapper {
              border-radius: 8px;
              font-size: 14px;
              padding: 8px 13px;
              box-shadow: 1px 1px 2px $scale13;
              line-height: 21px;

              p {
                color: $white-darker;
              }
            }
          }
        }
      }
    }

    .paginationWrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 30px;
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;

    img {
      width: 200px;
      height: 200px;
    }

    h2 {
      margin-top: 30px;
    }

    .resetDataButtonWrapper {
      margin-top: 20px;
    }
  }

  .loadingWrapper {
    margin-top: 30px;
    padding: 0 40px 40px;
  }
}

.rerunEntityEvalutionModalWrapper {
  height: 100%;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 30px;
  }

  h2,
  p {
    color: $reviewer-dark;
  }

  h2 {
    font-size: 24px;
    margin: 40px 0 0 0;
  }

  p {
    margin-top: 14px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .buttonWrapper {
    display: flex;
    margin-top: 20px;

    button:first-child {
      margin-right: 10px;
    }
  }
}
