.scroll {
  display: block;
  position: relative;
  overflow-y: auto;
  max-height: 31.5rem;
  max-width: 37.5rem;
  width: 100%;
  border: 0.5px solid #95989a;
  border-radius: 0.5rem;

  header {
    position: sticky;
    top: 0;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #95989a;
    align-items: center;
    background: #ffffff;

    h2 {
      font-size: 1.125rem;
      color: #3c405f;
      font-weight: 600;
    }

    .actions {
      display: flex;
      flex-direction: row;
      max-width: 25rem;

      button {
        margin-right: 0.5rem;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;

  .block {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    border: 1px solid #f0f0f0;
    margin: 1rem;
    background: #f2f2f2;
    border-radius: 0.5rem;

    .blockHeader {
      padding: 0.6rem;
      background: #ffde7c;
      border-radius: 0.5rem;
    }

    .blockContent {
      padding: 0.6rem;
    }

    .blockTextarea {
      width: 100%;
      height: 20rem;
      padding: 0.6rem;
      border: none;
    }

    .blockRaw {
      display: flex;
      flex-direction: column;
      padding: 0.6rem;
    }
  }

  .fullBlock {
    width: 100%;
    max-width: 31.5rem;
  }
}
