@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
      }
    }
    
    .RightButtonsContainer{
      display: flex;
      button:first-child{
        margin-right: 10px;
      }
    }
    button {
      height: min-content;
    }
  }

  .fieldset {
    padding: 30px 40px 40px;
    border: none;

    .textMarkerVersionSelect {
      position: absolute;
      right: 60px;
      top: 210px;
    }

    .selectWrapper {
      width: 300px;
    }

    input[type="text"],
    textarea {
      width: 300px;
    }

    .sentenceValidationWrapper {
      display: flex;
      align-items: center;
      margin-top: 40px;

      button {
        margin: 20px 0 0 20px;
        height: fit-content;
      }
    }

    .resultsWrapper {
      max-width: 440px;
      margin-top: 20px;

      .result {
        font-weight: bold;

        svg {
          margin-right: 10px;
        }
      }
    }

    .loadingRowWrapper {
      margin-top: 14px;
    }

    legend {
      font-weight: bold;
      font-size: 18px;
      margin: 30px 0 0 0;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: 36px;
    }

    label:first-child {
      margin-top: 0;
    }

    input[type="checkbox"],
    input[type="checkbox"] + label {
      margin-top: 30px;
    }

    .errorLabel {
      color: $new-error-red;
      font-size: 16px;
      display: block;
      padding-left: 4px;
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;

    img {
      width: 200px;
      height: 200px;
    }

    h2 {
      margin-top: 30px;
    }

    .resetDataButtonWrapper {
      margin-top: 20px;
    }
  }
}
