@import "../../utils/styles/variables";

p,
h1,
h2,
td,
th,
li {
  color: $reviewer-dark;
}

.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .leftButtons {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    .rightButtons {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 10px;
      }
    }

    button {
      height: min-content;
    }

    .reExecuteTestTooltip {
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      font-size: 14px;
      padding: 20px 13px;
      box-shadow: 1px 1px 2px $scale13;
      line-height: 21px;

      .wrapper {
        width: 200px;

        p {
          color: $white;
        }
      }
    }
  }

  .fieldset {
    max-width: 100%;
    padding: 30px 40px 40px 40px;
    border: none;

    .testVersionSelectWrapper {
      display: flex;
      justify-content: flex-end;
    }

    .precisionPercentageWrapper {
      margin-bottom: 40px;
      padding-right: 30px;

      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          margin-bottom: 12px;
        }
      }
    }

    .filterWrapper {
      display: flex;
      align-items: center;

      .selectWrapper {
        margin-right: 40px;
      }

      button {
        margin-top: 20px;
      }
    }

    table {
      width: 100%;
      margin-top: 30px;

      th,
      td {
        text-align: left;
        padding: 10px 0;
        border-bottom: 1px solid $new-light-gray;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.leftContext {
          direction: rtl;
        }

        &.rightContext {
          direction: ltr;
        }
      }

      td {
        select {
          max-width: 48px;
          background-color: transparent;
          cursor: pointer;
          transition: filter 0.2s linear;
          padding: 8px;
          border: 1px solid;
          border-radius: 4px;
          font-weight: bold;

          &:hover {
            filter: brightness(80%);
          }
        }

        .entityRedirectWrapper {
          svg {
            margin-left: 8px;
            transition: filter 0.2s linear;

            &:hover {
              filter: brightness(80%);
            }
          }
        }
      }

      tr {
        td:nth-child(3) {
          color: #eb5757;
          font-weight: bold;
        }
      }
    }

    .paginationWrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 30px;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: 18px;
    }

    label:first-child {
      margin-top: 0;
    }
  }

  .loadingWrapper {
    padding: 80px 40px 30px;

    div[data-testid="loading-row"] {
      margin-bottom: 20px;
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 370px;

    img {
      width: 200px;
      height: 200px;
    }

    h2 {
      margin-top: 30px;
    }

    .resetDataButtonWrapper {
      margin-top: 20px;
    }
  }
}
