@import "../../utils/styles/variables";
@import "../../utils/styles/mixins";

.container {
  width: 100%;
  min-height: calculateRem(800px);
  background-color: $white;
  border-radius: 8px;

  h1,
  h2,
  td,
  th {
    color: $reviewer-dark;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $reopen-test-grey;

    h1 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 0;
    }

    button {
      height: min-content;
    }
  }

  .tableWrapper {
    padding: 30px 30px 40px;

    .searchContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;

      input[data-testid="search"] {
        padding-right: 0;
      }

      .numberOfEntitiesPerPageSelectTooltip {
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        font-size: 14px;
        padding: 20px 13px;
        box-shadow: 1px 1px 2px $scale13;
        line-height: 21px;

        .wrapper {
          width: 200px;

          p {
            color: $white;
          }
        }
      }
    }

    table {
      width: 100%;
      margin-top: 30px;
      padding-left: 18px;

      th,
      td {
        text-align: left;
        padding: 14px 0;
        border-bottom: 1px solid $new-light-gray;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      td {
        button,
        svg {
          background-color: transparent;
          cursor: pointer;
          border: 0;
          transition: filter 0.3s linear;

          &:hover {
            filter: brightness(80%);
          }
        }

        button {
          margin-left: 14px;
        }
      }
    }

    .paginationWrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 30px;
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;

    img {
      width: 200px;
      height: 200px;
    }

    h2 {
      margin-top: 30px;
    }

    .resetDataButtonWrapper {
      margin-top: 20px;
    }
  }

  .loadingWrapper {
    margin-top: 30px;
    padding: 0 40px 40px;
  }
}
