@import "../../utils/styles/variables";
@import "../../utils/styles/mixins";

form.container {
  width: 100%;
  min-height: calculateRem(800px);
  background-color: $white;
  border-radius: 8px;

  p,
  h1,
  h2,
  h3,
  h4,
  tr,
  td,
  th {
    color: $reviewer-dark;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calculateRem(18px) calculateRem(30px);
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: calculateRem(10px);
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: calculateRem(16px);
        color: $reviewer-dark;
      }
    }

    button {
      height: min-content;
    }
  }

  .fieldset {
    width: 100%;
    padding: calculateRem(30px) calculateRem(40px) calculateRem(40px);
    border: none;

    .loadingWrapper {
      margin-top: calculateRem(40px);
    }

    textarea {
      width: calculateRem(300px);
      resize: both;
      overflow: auto;

      &.error {
        border-color: $new-error-red;
      }
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: calculateRem(18px);
    }

    label:first-child {
      margin-top: 0;
    }

    .errorLabel {
      color: $new-error-red;
      font-size: calculateRem(16px);
      display: block;
      padding-left: calculateRem(4px);
    }
  }

  .resultsWrapper {
    width: 100%;
    padding: 0 calculateRem(40px) calculateRem(40px);

    table {
      width: 100%;
      margin-top: calculateRem(30px);
      padding-left: calculateRem(18px);

      th,
      td {
        text-align: left;
        padding: calculateRem(14px) 0;
        border-bottom: 1px solid $new-light-gray;
        max-width: calculateRem(100px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      td {
        button,
        svg {
          background-color: transparent;
          cursor: pointer;
          border: 0;
          transition: filter 0.3s linear;

          &:hover {
            filter: brightness(80%);
          }
        }

        button {
          margin-left: calculateRem(14px);
        }
      }
    }

    .plagiarismPercentage {
      margin-top: calculateRem(30px);
      padding-left: calculateRem(20px);
      font-size: calculateRem(18px);
    }
  }
}
