@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  .rightContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    .helpIconButton {
      border: none;
      background: none;
    }

    svg {
      cursor: pointer;
      margin-right: 0.8rem;
      color: $new-yellow;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      h1 {
        text-transform: uppercase;
        font-size: 1rem;
      }

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  fieldset {
    padding-block: 30px 40px;
    padding-inline: 40px;
    border: none;

    input[type="text"],
    textarea {
      width: 300px;

      &::placeholder {
        font-size: 14px;
      }
    }

    label[id="tag-error"] {
      color: $new-error-red;
    }

    input ~ label,
    div > label {
      margin-top: 24px;
    }

    .tagSelectWrapper {
      > div {
        display: flex;
        align-items: center;
        gap: 20px;
        min-width: 300px;
      }
    }

    .newTagInputButtonsWrapper {
      margin-top: 12px;
      display: flex;
      gap: 10px;
    }
  }
}
