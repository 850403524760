@import "../../utils/styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 700px;

  img {
    width: 100%;
    max-width: 600px;
    max-height: 400px;
    height: auto;
  }

  h1 {
    margin: 40px 0;
    font-weight: bold;
    color: $new-teacher-primary;
  }

  div {
    display: flex;

    button + button {
      margin-left: 14px;
    }
  }
}
