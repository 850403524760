.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;

  img {
    width: 200px;
    height: 200px;
  }

  h2 {
    margin-top: 30px;
  }

  .resetDataButtonWrapper {
    margin-top: 20px;
  }
}
