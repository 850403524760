@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4 {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    button {
      height: min-content;
    }
  }

  .fieldset {
    padding: 30px 40px 40px;
    border: none;

    input[type="text"],
    textarea {
      width: 300px;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: 36px;
    }

    label:first-child {
      margin-top: 0;
    }
  }
}
