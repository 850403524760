.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  input[data-testid="search"] {
    padding-right: 0;
  }
}

.searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  max-width: 31.25rem;
}

.searchBy {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.313rem;
  width: 100%;
  max-width: 7.5rem;
  transform: translateY(-0.625rem);
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 18.75rem;
}
