@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
    border-bottom: 1px solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      h1 {
        text-transform: uppercase;
        font-size: 16px;
      }
    }
    .RightButtonsContainer{
      display: flex;
      button:first-child{
        margin-right: 10px;
      }
    }
    button {
      height: min-content;
    }
  }

  .fieldset {
    padding: 30px 40px 40px;
    border: none;

    .grammarRuleVersionSelect {
      position: absolute;
      right: 60px;
      top: 210px;
    }

    input[type="text"],
    textarea {
      width: 300px;
    }

    .sentenceValidationWrapper {
      display: flex;
      align-items: center;
      margin-top: 40px;

      button {
        margin: 20px 0 0 20px;
        height: fit-content;
      }
    }

    .resultsWrapper {
      max-width: 440px;
      margin-top: 20px;

      .result {
        font-weight: bold;
      }
    }

    .testRulerValidationWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      .affectedRules {
        width: 100%;
        display: flex;
        flex-direction: row;

        b {
          margin-right: 8px;
        }

        ul {
          display: flex;

          li {
            list-style: none;
            width: calc(100% / 3);
            text-align: center;
          }
        }
      }

      .testValidateTable {
        width: 100%;
        padding: 10px;
        border-radius: 10px;

        svg {
          margin-right: 10px;
        }

        table {
          width: 100%;
          margin-top: 30px;
          padding-left: 18px;

          th,
          td {
            text-align: left;
            padding: 14px 0;
            border-bottom: 1px solid $new-light-gray;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .loadingRowWrapper {
      margin-top: 14px;
    }

    legend {
      font-weight: bold;
      font-size: 18px;
      margin: 30px 0 0 0;
    }

    .examplesWrapper,
    .suggestionsWrapper {
      padding-left: 40px;

      .title {
        display: flex;
        margin-top: 40px;

        h4 {
          margin-top: 0;
        }

        button {
          width: 30px;
          height: 30px;
          margin-left: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          bottom: 6px;
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid #999;
          cursor: pointer;
          transition: filter 0.2s linear;

          &.selected {
            border-color: $new-error-red !important;
          }

          &:hover {
            filter: brightness(80%);
          }
        }

        .removeNewSuggestionButton,
        .removeNewExampleForm {
          border-radius: 50%;
        }
      }

      .inputWrapper {
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding-left: 30px;

        label {
          margin: 0 10px 0 10px;
        }

        .exampleInputWrapper {
          margin-right: 30px;

          label {
            margin: 0 0 10px 0;
          }
        }
      }
    }

    .addExampleButton,
    .addSuggestionButton {
      margin: 60px 0;
    }

    label:not(.errorLabel),
    label:not(:first-child) {
      margin-top: 36px;
    }

    label:first-child {
      margin-top: 0;
    }

    input[type="checkbox"],
    input[type="checkbox"] + label {
      margin-top: 30px;
    }

    .errorLabel {
      color: $new-error-red;
      font-size: 16px;
      display: block;
      padding-left: 4px;
    }
  }

  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;

    img {
      width: 200px;
      height: 200px;
    }

    h2 {
      margin-top: 30px;
    }

    .resetDataButtonWrapper {
      margin-top: 20px;
    }
  }
}
