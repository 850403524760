@import "../../utils/styles/variables";

header.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  border-bottom: 1px solid $new-light-gray;

  .buttonContainer {
    display: flex;
    align-items: center;

    button {
      margin-right: 10px;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }

    h1 {
      text-transform: uppercase;
      font-size: 1rem;
    }
  }
}
