@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  fieldset {
    display: flex;
    flex-direction: row-reverse;
    align-items: space-between;
    justify-content: space-between;
    padding: 30px 40px 40px;
    border: none;

    input[type="text"],
    textarea {
      width: 300px;

      &[disabled=""] {
        background-color: hsl(0, 0%, 95%);
        cursor: not-allowed;
      }
    }

    input ~ label,
    div > label {
      margin-top: 24px;
    }

    .promptVersionSelectWrapper {
      margin-bottom: 1.25rem;
      label {
        margin-top: 0rem;
      }
    }
  }

  .blocksWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    border: 1px solid #95989a;
    max-width: 520px;
    border-radius: 6px;
    padding: 12px;
    align-items: center;
    margin-top: 24px;
  }

  .blocksError {
    color: #f24e54;
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    padding: 10px;
    padding-inline: 20px;
    border: 1px solid #95989a;
    border-radius: 20px;

    &:hover {
      color: #e6ac00;
      border-color: #e6ac00;
    }
  }

  .excludeIcon {
    margin-left: 5px;

    &:hover {
      color: #f24e54;
    }
  }

  .blockOver {
    color: #e6ac00;
    border-color: #e6ac00;
  }

  .previewWrapper {
    max-width: 600px;
    width: 100%;
  }

  .fieldsWrapper {
    max-width: 600px;
    width: 100%;
  }

  figure {
    display: flex;
    max-width: 520px;
    align-items: center;
    gap: 8px;
    margin-top: 1.2rem;

    p {
      font-size: 14px;
    }
  }
}
