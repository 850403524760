@import "../../utils/styles/variables";
@import "../../utils/styles/mixins";

.modalWrapper {
  height: 100%;
  margin: 0 auto;
  padding: 0 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2,
  p {
    color: $reviewer-dark;
  }

  h2 {
    font-size: calculateRem(24px);
    margin-top: calculateRem(20px);
    margin-bottom: 0;
  }

  p {
    margin: 18px 0;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    max-width: 260px;
  }

  ul {
    margin-block: 1rem;
    width: 100%;

    ul {
      margin-block: 0;
      padding-left: calculateRem(20px);
      padding-top: calculateRem(4px);

      li:last-child {
        margin-bottom: calculateRem(14px);
      }
    }
  }
}
