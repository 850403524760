@import "./roleColors";

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
@mixin hoverTranslate() {
  transform: translate(0, -2px);
  transition: transform 0.5s;
}
@mixin boxShadow() {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
@mixin bgHover {
  transition: background-color 0.4s ease;
}
@mixin truncateWithNumberLine($lines) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}
@mixin hoverEffect() {
  transition: transform 0.4s;
  position: relative;
  will-change: translate, transition;

  &:hover {
    @include hoverTranslate();
  }

  &:active {
    transform: translate(0, -1px);
  }

  &:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: all 0.5s;
  }

  &:hover:after {
    opacity: 1;
    @include boxShadow();
  }
}
@mixin simpleHoverEffect() {
  transition: transform 0.4s;
  position: relative;
  will-change: translate, transition;

  &:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.15);
    opacity: 0;
    transition: all 0.5s;
  }

  &:hover:after {
    opacity: 1;
  }
}
@mixin buttonColors() {
  &.default {
    background-color: $gray;
    color: $medium-dark;
  }

  &.basic {
    background-color: $white;
    color: $medium-gray;

    &:hover {
      color: $dark-gray;
    }
  }

  &.defaultPrimary {
    background-color: $deep-blue;
    color: $white;
  }

  &.primary {
    &.teacher {
      background-color: $teacher;
      color: $white;
    }

    &.student {
      background-color: $student;
      color: $white;
    }

    &.reviewer {
      background-color: $reviewer;
      color: $white;
    }

    &.super_user,
    &.admin {
      background-color: $admin;
      color: $white;
    }

    &.advanced {
      background-color: $advanced;
      color: $dark;
    }

    &.dark {
      background-color: $advanced;
      color: $dark;
    }
  }

  &.success {
    background-color: $success;
    color: $white;
  }

  &.danger {
    background-color: $danger;
    color: $white;
  }
}
@mixin modalityColors() {
  &.default {
    color: $gray;
  }

  &.defaultPrimary {
    color: $deep-blue;
  }

  &.primary {
    &.teacher {
      color: $teacher;
    }

    &.student {
      color: $student;
    }

    &.reviewer {
      color: $reviewer;
    }

    &.super_user,
    &.admin {
      color: $admin;
    }

    &.advanced {
      color: $advanced;
    }

    &.success {
      color: $success;
    }

    &.danger {
      color: $danger;
    }
  }
}
@mixin roleColorsHeader() {
  &.default {
    background-color: $white;
    color: $white;
  }

  &.teacher {
    background-color: $teacher-header;
    color: $medium-dark;
  }

  &.student {
    background-color: $student-header;
    color: $white;
  }

  &.reviewer {
    background-color: $reviewer-header;
    color: $white;
  }

  &.super_user,
  &.admin {
    background-color: $admin-header;
    color: $white;
  }
}
@mixin linearGradient($orietation, $from, $to) {
  @if $orietation == "vertical" {
    $orietation: 95deg;
  }
  @if $orietation == "horizontal" {
    $orietation: 180deg;
  }

  background: $to;
  background: linear-gradient($orietation, $from 0, $to 50%);
}
@mixin fadeInAnimation {
  animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-fill-mode: both;
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}
@keyframes likeAnimation {
  0% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(-40deg);
  }

  85% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@mixin listPageStyles {
  width: 100%;
  min-height: 800px;
  background-color: $white;
  border-radius: 8px;

  h1,
  h2,
  td,
  th {
    color: $reviewer-dark;
  }

  .tableWrapper {
    padding: 30px 30px 40px;

    .paginationWrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 30px;
    }
  }

  .loadingWrapper {
    margin-top: 30px;
    padding: 0 40px 40px;
  }
}
