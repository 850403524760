@import "../../utils/styles/variables";

.container {
  max-width: 100%;
  min-height: 701px;

  h1,
  li {
    color: $reviewer-dark;
  }

  h1 {
    font-weight: bold;
  }

  .cardsGrid {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    a {
      text-decoration: none;

      li {
        width: 170px;
        height: 210px;
        margin: 30px 30px 0 0;
        background-color: #fefefe;
        box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        transition: transform 0.4s;
        font-size: 1rem;
        text-align: center;
        padding: 12px;

        img {
          width: 40px;
        }

        svg,
        img {
          margin-bottom: 14px;
        }

        &:hover {
          transform: translate(0, -5px);
        }
      }
    }
  }
}
