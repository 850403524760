@import "../../utils/styles/variables";

.table {
  width: 100%;
  margin-top: 30px;
  padding-left: 18px;

  th,
  td {
    text-align: left;
    padding: 14px;
    border-bottom: 1px solid $new-light-gray;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td {
    button,
    svg {
      background-color: transparent;
      cursor: pointer;
      border: 0;
      transition: filter 0.3s linear;

      &:hover {
        filter: brightness(80%);
      }
    }

    button {
      margin-left: 14px;
    }
  }
}
