@import "../../utils/styles/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 30px;
  border-bottom: 1px solid $reopen-test-grey;

  h1 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
  }

  button {
    height: min-content;
  }
}
