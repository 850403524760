.block {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 3px;
  padding-block: 10px;
  padding-inline: 20px;
  border: 1px solid #95989a;
  border-radius: 20px;

  &:hover {
    color: #e6ac00;
    border-color: #e6ac00;
  }
}

.withVersionSelect {
  padding-block: 5px;
}

.excludeIcon {
  margin-left: 5px;

  &:hover {
    color: #f24e54;
  }
}

.blockOver {
  color: #e6ac00 !important;
  border-color: #e6ac00 !important;
}

.unremovable {
  color: #3c405f;
  border-color: #3c405f;
  font-weight: 600;
}

.fixed {
  color: #4e9076;
  border-color: #4e9076;
  font-weight: 600;
}
