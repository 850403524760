@import "../../utils/styles/variables";

.deleteModalWrapper {
  height: 100%;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-top: 30px;
  }

  h2,
  p {
    color: $reviewer-dark;
  }

  h2 {
    font-size: 24px;
    margin: 40px 0 0 0;
  }

  p {
    margin-top: 14px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .buttonWrapper {
    display: flex;
    margin-top: 20px;

    button:first-child {
      margin-right: 10px;
    }
  }
}
