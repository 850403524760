@import "../../utils/styles/variables";

p,
h1,
h2,
h3,
h4,
td,
th,
legend {
  color: $reviewer-dark;
}

form.container {
  width: 100%;
  min-height: 50rem;
  background-color: $white;
  border-radius: 0.5rem;

  .test {
    display: flex;
    flex-direction: row;
  }

  .helpIconButton {
    border: none;
    background: none;

    svg {
      cursor: pointer;
      margin-left: 0.2rem;
      color: $new-yellow;
    }
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.125rem 1.875rem;
    border-bottom: 0.0063rem solid $new-light-gray;

    .buttonContainer {
      display: flex;
      align-items: center;

      h1 {
        text-transform: uppercase;
        font-size: 1rem;
      }

      button {
        margin-right: 0.625rem;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  fieldset {
    display: flex;
    flex-direction: row-reverse;
    align-items: space-between;
    justify-content: space-between;
    padding: 1.875rem 2.5rem 2.5rem;
    border: none;

    input[type="text"],
    textarea {
      width: 18.75rem;
      font-size: 1rem;

      &::placeholder {
        font-size: 0.875rem;
      }
    }

    input ~ label,
    div > label {
      margin-top: 1.5rem;
    }

    .previewWrapper {
      max-width: 30.5rem;
      width: 100%;
    }

    .fieldsWrapper {
      max-width: 32.5rem;
      width: 100%;
    }
  }

  div[data-testid="loading-form"] {
    width: 100%;
    padding: 18px 30px;
  }

  .error {
    color: #f24e54;
  }

  figure {
    display: flex;
    max-width: 32.5rem;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1.2rem;

    p {
      font-size: 0.875rem;
    }
  }
}
